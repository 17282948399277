import React from "react";
import styled from "styled-components";
import bolt from "../../images/companies/bolt.png";
import silisoul from "../../images/companies/silisoul.png";
import soroco from "../../images/companies/soroco.svg";
import i8labs from "../../images/companies/i8labs.png";
import qblocks from "../../images/companies/qblocks.jpeg";
import fimo from "../../images/companies/fimo.jpeg";

const WorkExperience = () => {
    return (
        <WorkExperienceContainer>
            <WorkPosition>
                <WorkClosure>
                    <CompanyLink href="https://bolt.earth" target="_blank">
                        <img
                            src={bolt}
                            style={{
                                width: "2rem",
                                height: "2rem",
                                marginRight: "8px",
                                marginBottom: "-4px",
                            }}
                        />
                        Bolt.Earth
                    </CompanyLink>{" "}
                    - Bangalore, India
                </WorkClosure>
                <PositionTitle>
                    Software Development Engineer - II
                </PositionTitle>
                <Date>04/2023 - Present</Date>
            </WorkPosition>

            <WorkPosition>
                <WorkClosure>
                    <CompanyLink href="https://bolt.earth" target="_blank">
                        <img
                            src={bolt}
                            style={{
                                width: "2rem",
                                height: "2rem",
                                marginRight: "8px",
                                marginBottom: "-4px",
                            }}
                        />
                        Bolt.earth
                    </CompanyLink>{" "}
                    - Bangalore
                </WorkClosure>
                <PositionTitle>Software Development Engineer - I</PositionTitle>
                <Date>04/2022 - 03/2023</Date>
            </WorkPosition>

            <WorkPosition>
                <WorkClosure>
                    <CompanyLink href="https://silisoul.com" target="_blank">
                        <img
                            src={silisoul}
                            style={{
                                width: "2rem",
                                height: "2rem",
                                marginRight: "8px",
                                marginBottom: "-4px",
                            }}
                        />
                        Silisoul
                    </CompanyLink>{" "}
                    - Bangalore
                </WorkClosure>
                <PositionTitle>
                    Technical Lead - Full Stack Developer (Consultant)
                </PositionTitle>
                <Date>10/2023 - Present</Date>
            </WorkPosition>

            <WorkPosition>
                <WorkClosure>
                    <CompanyLink href="https://soroco.com" target="_blank">
                        <img
                            src={soroco}
                            style={{
                                width: "2rem",
                                height: "2rem",
                                marginRight: "8px",
                                marginBottom: "-4px",
                            }}
                        />
                        Soroco India
                    </CompanyLink>{" "}
                    - Bangalore, India
                </WorkClosure>
                <PositionTitle>
                    Software Developer - Backend (Full Time)
                </PositionTitle>
                <Date>07/2021 - 03/2022</Date>
            </WorkPosition>

            <WorkPosition>
                <WorkClosure>
                    <CompanyLink href="https://www.i8labs.io" target="_blank">
                        <img
                            src={i8labs}
                            style={{
                                width: "5rem",
                                height: "1.5rem",
                                marginRight: "8px",
                                marginBottom: "-4px",
                            }}
                        />
                        i8labs
                    </CompanyLink>{" "}
                    - Gurgaon, India
                </WorkClosure>
                <PositionTitle>
                    Software Developer - Full Stack (Intern)
                </PositionTitle>
                <Date>03/2021 - 07/2021 & 08/2020 - 11/2020</Date>
            </WorkPosition>

            <WorkPosition>
                <WorkClosure>
                    <CompanyLink
                        href="https://www.qblocks.cloud"
                        target="_blank"
                    >
                        <img
                            src={qblocks}
                            style={{
                                width: "2rem",
                                height: "2rem",
                                marginRight: "8px",
                                marginBottom: "-4px",
                            }}
                        />
                        Qblocks
                    </CompanyLink>{" "}
                    - Bangalore, India
                </WorkClosure>
                <PositionTitle>
                    Software Developer - Full Stack (Full Time)
                </PositionTitle>
                <Date>11/2020 - 02/2021</Date>
            </WorkPosition>

            <WorkPosition>
                <WorkClosure>
                    <CompanyLink
                        href="https://in.linkedin.com/company/fimofitness"
                        target="_blank"
                    >
                        <img
                            src={fimo}
                            style={{
                                width: "2rem",
                                height: "2rem",
                                marginRight: "8px",
                                marginBottom: "-4px",
                            }}
                        />
                        FIMO TECHNOLOGIES
                    </CompanyLink>{" "}
                    - Hyderabad
                </WorkClosure>
                <PositionTitle>
                    Software Developer - Full Stack (Intern)
                </PositionTitle>
                <Date>12/2019 - 05/2020</Date>
            </WorkPosition>
        </WorkExperienceContainer>
    );
};

const WorkExperienceContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    margin-left: 40%;
    @media (max-width: 768px) {
        margin-left: 10px;
        margin-bottom: 40px;
        margin-top: 40px;
    }
    @media (max-width: 480px) {
        margin-left: 10px;
        margin-bottom: 40px;
        margin-top: 40px;
    }
`;

const WorkPosition = styled.div`
    margin-bottom: 40px;
`;

const WorkClosure = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 8px;
`;

const PositionTitle = styled.h3`
    font-size: 1.25rem;
    margin-bottom: 8px;
`;

const CompanyLink = styled.a`
    color: #007bff;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const Date = styled.p`
    font-weight: bold;
    margin-bottom: 16px;
`;

export default WorkExperience;
