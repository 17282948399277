import styled from "styled-components";
import bg from "./images/profile/bg1.jpeg";
import { useMemo, useState } from "react";
import { Orb } from "./Components/Orb/Orb.js";
import { navItems } from "./store/navItems.js";
import { MainLayout } from "./styles/Layout.js";
import Navigation from "./Components/Navigation/Navigation.js";
import Footer from "./Components/Navigation/Footer.js";
import { useWindowSize } from "./utils/useWindowSize.js";
import GitHubRepos from "./Components/GitHub/Repos.js";
import Resume from "./Components/Resume/resume.js";
import About from "./Components/About/About.js";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import Home from "./Components/Home/Home.js";
import WorkExperience from "./Components/Experience/Experience.js";
import { Blogs } from "./Components/Blogs/Blogs.js";
import { Routes, Route } from "react-router-dom";
import { navigation } from "./utils/navigations.js";

export default function App() {
    const location = window.location;
    const [active, setActive] = useState(
        location.pathname.replace("/", "").charAt(0).toUpperCase() +
            location.pathname.replace("/", "").slice(1)
    );
    const { height } = useWindowSize();

    const orbMemo = useMemo(() => {
        return <Orb />;
    }, []);

    const displayData = () => {
        switch (active) {
            case navItems.Home:
                return <Home />;
            case navItems.About:
                return (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <About />
                    </div>
                );
            case navItems.Projects:
                return (
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        <GitHubRepos
                            username="SatyamAnand98"
                            maxPages={3}
                            hideForks={true}
                        />
                    </div>
                );
            case navItems.Contact:
                return (
                    <div
                        style={{
                            textAlign: "center",
                            marginTop: `${height / 3.5}px`,
                            marginBottom: `${height / 3.5}px`,
                        }}
                    >
                        <p>
                            Feel free to reach out to me on any of the following
                            platforms:
                        </p>
                    </div>
                );
            case navItems.Resume:
                return (
                    <div>
                        <Resume />
                    </div>
                );
            case navItems.Social:
                return (
                    <div
                        style={{
                            textAlign: "center",
                            marginTop: `${height / 3.5}px`,
                            marginBottom: `${height / 3.5}px`,
                        }}
                    >
                        <p>Blogs, Social Media, etc.</p>
                    </div>
                );
            case navItems.Blog:
                return <Blogs />;
            case navItems.Journey:
                return <WorkExperience />;
            default:
                return "";
        }
    };

    // return (
    //     <AppStyled className="App">
    //         {orbMemo}
    //         <MainLayout>
    //             <Navigation active={active} setActive={setActive} />
    //             <main className="main">{displayData()}</main>
    //             <Footer />
    //         </MainLayout>
    //         <Analytics />
    //         <SpeedInsights />
    //     </AppStyled>
    // );

    return (
        <AppStyled>
            <Navigation active={active} setActive={setActive} />
            <Routes>
                <Route path={navigation[0].path} element={<Home />} />
                <Route path={navigation[1].path} element={<About />} />
                <Route
                    path={navigation[2].path}
                    element={
                        <GitHubRepos
                            username="SatyamAnand98"
                            maxPages={3}
                            hideForks={true}
                        />
                    }
                />
                <Route path={navigation[3].path} element={<Resume />} />
                <Route path={navigation[4].path} element={<Blogs />} />
                <Route path={navigation[5].path} element={<WorkExperience />} />
                <Route path="*" element={<Home />} />
            </Routes>
            <Footer />
            <Analytics />
            <SpeedInsights />
        </AppStyled>
    );
}

const AppStyled = styled.div`
    height: auto;
    background-image: url(${bg});
    position: inherit;
    animation: burst 1.5s ease-in-out;
    display: flex;
    flex-direction: column;

    .main {
        flex: 1;
        &::-webkit-scrollbar {
            width: 0;
        }
    }

    @keyframes burst {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }

    main {
        flex: 1;
        background: rgba(40, 36, 41, 0.07);
        border: 3px solid #282429;
        // backdrop-filter: blur(4.5px);
        border-radius: 32px;
        &::-webkit-scrollbar {
            width: 0;
        }
    }

    @media (max-width: 768px) {
        main {
            border-radius: 16px;
        }
    }

    @media (max-width: 480px) {
        main {
            border-radius: 8px;
        }
    }
`;
